<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- <b-col cols="12" md="2" class="d-flex align-items-left justify-content-start ">
              <v-select :options="statusWebsiteOptions" v-model="statusWebsite" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Website Status Selection" />
            </b-col> -->

            <b-col cols="12" md="4" class="d-flex align-items-left justify-content-start ">
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="treeStatus" valueFormat="object" :limit="2" class="per-page-selector d-inline-block" style="position: absolute; width: 97% !important" placeholder="Inventory Selection(s)" />
              <!-- <v-select :options="statusOptions" v-model="status" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Inventory Status Selection" /> -->
            </b-col>

            <b-col cols="12" md="2" class="d-flex align-items-left justify-content-start ">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listInventorys"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="inHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <!-- Column: Id -->
          <template #cell(stockNumber)="data">
            <!-- <b-link :to="{ name: 'inventory-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.item.stockNumber }}</strong>
          </template>

          <!-- Column: vehiclePicture -->
          <template #cell(selectedImage)="data">
            <!-- https://api.mrorhan.com/storage/app/public/images/16483743079635d222-18c4-3e11-93ff-ee7afd164a04.png -->
            <!-- <b-img :src="baseURL + data.item.selectedImage" width="150px" class="product-img " fluid /> -->
            <!-- <b-img thumbnail :src="baseURL + data.item.selectedImage" class="product-img d-block" fluid /> -->

            <b-img-lazy thumbnail class="rounded" width="100px" @error.native="onError" v-if="data.item.selectedImage != null" :src="baseURL + data.item.selectedImage" />
            <b-img-lazy thumbnail class="rounded" width="100px" v-else src="@/assets/images/no-car.png" />
          </template>

          <!-- Column: Title -->
          <template #cell(titleValue)="data">
            <span class="font-weight-bold"> {{ data.item.titleValue ? data.item.titleValue : 'N/A' }} </span>
          </template>

          <!-- Column: Listing Price -->
          <template #cell(listingPrice)="data">
            <span class="font-weight-bold"> {{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }} </span>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="info" class="badge-glow">On Lease</b-badge>

            <b-badge v-if="data.item.status == 'B'" pill variant="success" class="badge-glow">Off Lease </b-badge>

            <b-badge v-if="data.item.status == 'C'" pill variant="danger" class="badge-glow">Canceled Lease </b-badge>
          </template>

          <!-- Column: Publish -->
          <template #cell(publish)="data">
            <b-badge v-if="data.item.publish == 'yes'" active variant="light-success" class="badge-glow ">Published </b-badge>
            <b-badge v-if="data.item.publish == 'no'" variant="light-danger" class="badge-glow ">Unpublished </b-badge>
          </template>

          <template #cell(aspiration)="data"> {{ data.item.aspiration ? data.item.aspiration : 'N/A' }} </template>
          <template #cell(baseMsrp)="data"> {{ data.item.baseMsrp ? data.item.baseMsrp : 'N/A' }} </template>
          <template #cell(boreStroke)="data">{{ data.item.boreStroke ? data.item.boreStroke : 'N/A' }} </template>
          <template #cell(chassisCode)="data">{{ data.item.chassisCode ? data.item.chassisCode : 'N/A' }} </template>
          <template #cell(chassisMaterials)="data"> {{ data.item.chassisMaterials ? data.item.chassisMaterials : 'N/A' }} </template>
          <template #cell(chassisType)="data"> {{ data.item.chassisType ? data.item.chassisType : 'N/A' }} </template>
          <template #cell(collection)="data">
            <b-badge v-if="data.item.collection == true" active variant="light-success" class="badge-glow ">Collection </b-badge>
            <b-badge v-if="data.item.collection == false" variant="light-danger" class="badge-glow ">Non Collection </b-badge>
          </template>
          <template #cell(compressionRatio)="data"> {{ data.item.compressionRatio ? data.item.compressionRatio : 'N/A' }} </template>
          <template #cell(condition)="data">
            <b-badge v-if="data.item.condition == 'NEW' || data.item.condition == 'New'" active variant="light-info" class="badge-glow ">New</b-badge>
            <b-badge v-if="data.item.condition == 'USED' || data.item.condition == 'Used'" variant="light-success" class="badge-glow ">Used </b-badge>
          </template>

          <template #cell(consigment)="data">
            <b-badge v-if="data.item.consigment == true" active variant="light-success" class="badge-glow ">Consignment </b-badge>
            <b-badge v-if="data.item.consigment == false" variant="light-danger" class="badge-glow ">Non Consignment </b-badge>
          </template>

          <template #cell(curbWeight)="data"> {{ data.item.curbWeight ? data.item.curbWeight : 'N/A' }} </template>
          <template #cell(delivered)="data">
            <b-badge v-if="data.item.delivered == 'yes'" active variant="light-success" class="badge-glow ">Delivered </b-badge>
            <b-badge v-if="data.item.delivered == 'no'" variant="light-danger" class="badge-glow ">Not Delivered </b-badge>
          </template>
          <template #cell(description)="data">{{ data.item.description ? data.item.description : 'N/A' }} </template>
          <template #cell(driveTrainValue)="data">{{ data.item.driveTrainValue ? data.item.driveTrainValue : 'N/A' }} </template>
          <template #cell(engineAlignment)="data"> {{ data.item.engineAlignment ? data.item.engineAlignment : 'N/A' }} </template>
          <template #cell(engineCode)="data"> {{ data.item.engineCode ? data.item.engineCode : 'N/A' }} </template>
          <template #cell(engineDisplacement)="data">{{ data.item.engineDisplacement ? data.item.engineDisplacement : 'N/A' }} </template>
          <template #cell(enginePosition)="data"> {{ data.item.enginePosition ? data.item.enginePosition : 'N/A' }} </template>
          <template #cell(extColourValue)="data">{{ data.item.extColourValue ? data.item.extColourValue : 'N/A' }} </template>
          <template #cell(frontAxleWidth)="data"> {{ data.item.frontAxleWidth ? data.item.frontAxleWidth : 'N/A' }} </template>
          <template #cell(frontBrakes)="data"> {{ data.item.frontBrakes ? data.item.frontBrakes : 'N/A' }} </template>
          <template #cell(frontSuspension)="data"> {{ data.item.frontSuspension ? data.item.frontSuspension : 'N/A' }} </template>
          <template #cell(frontTyres)="data"> {{ data.item.frontTyres ? data.item.frontTyres : 'N/A' }} </template>
          <template #cell(fuelSystem)="data">{{ data.item.fuelSystem ? data.item.fuelSystem : 'N/A' }}</template>
          <template #cell(fuelTankCapacity)="data"> {{ data.item.fuelTankCapacity ? data.item.fuelTankCapacity : 'N/A' }} </template>

          <template #cell(height)="data"> {{ data.item.height ? data.item.height : 'N/A' }} </template>
          <template #cell(homepage)="data">
            <b-badge v-if="data.item.homepage == true" active variant="light-success" class="badge-glow ">Homepage Listing</b-badge>
            <b-badge v-if="data.item.homepage == false" variant="light-danger" class="badge-glow ">Not on Homepage </b-badge>
          </template>
          <template #cell(intColourValue)="data">{{ data.item.intColourValue ? data.item.intColourValue : 'N/A' }} </template>
          <template #cell(interiorTrim)="data">{{ data.item.interiorTrim ? data.item.interiorTrim : 'N/A' }} </template>
          <template #cell(length)="data"> {{ data.item.length ? data.item.length : 'N/A' }} </template>
          <template #cell(location)="data">{{ data.item.location ? data.item.location : 'N/A' }} </template>
          <template #cell(makeValue)="data"> {{ data.item.makeValue ? data.item.makeValue : 'N/A' }} </template>
          <template #cell(maximumHorsepower)="data">{{ data.item.maximumHorsepower ? data.item.maximumHorsepower : 'N/A' }} </template>
          <template #cell(maximumTorque)="data"> {{ data.item.maximumTorque ? data.item.maximumTorque : 'N/A' }} </template>
          <template #cell(mileageUnit)="data"> {{ data.item.mileageUnit ? data.item.mileageUnit : 'N/A' }} </template>
          <template #cell(mileageValue)="data"> {{ data.item.mileageValue ? data.item.mileageValue : 'N/A' }} </template>
          <template #cell(modelValue)="data"> {{ data.item.modelValue ? data.item.modelValue : 'N/A' }} </template>
          <template #cell(modelVariation)="data">{{ data.item.modelVariation ? data.item.modelVariation : 'N/A' }}</template>
          <template #cell(modelYearValue)="data"> {{ data.item.modelYearValue ? data.item.modelYearValue : 'N/A' }} </template>
          <template #cell(msrpOptionsValue)="data"> {{ data.item.msrpOptionsValue ? data.item.msrpOptionsValue : 'N/A' }} </template>
          <template #cell(numberOfCylinders)="data"> {{ data.item.numberOfCylinders ? data.item.numberOfCylinders : 'N/A' }} </template>

          <template #cell(numberOfDoors)="data"> {{ data.item.numberOfDoors ? data.item.numberOfDoors : 'N/A' }} </template>
          <template #cell(numberOfValves)="data"> {{ data.item.numberOfValves ? data.item.numberOfValves : 'N/A' }} </template>
          <template #cell(origenalMarketValue)="data"> {{ data.item.origenalMarketValue ? data.item.origenalMarketValue : 'N/A' }} </template>
          <template #cell(rearAxleWidth)="data">{{ data.item.rearAxleWidth ? data.item.rearAxleWidth : 'N/A' }}</template>
          <template #cell(rearBrakes)="data"> {{ data.item.rearBrakes ? data.item.rearBrakes : 'N/A' }} </template>
          <template #cell(rearSuspension)="data"> {{ data.item.rearSuspension ? data.item.rearSuspension : 'N/A' }} </template>
          <template #cell(rearTyres)="data"> {{ data.item.rearTyres ? data.item.rearTyres : 'N/A' }} </template>

          <template #cell(received)="data">
            <b-badge v-if="data.item.received == 'yes'" active variant="light-success" class="badge-glow ">Received </b-badge>
            <b-badge v-if="data.item.received == 'no'" variant="light-danger" class="badge-glow ">Not Received </b-badge>
          </template>

          <template #cell(seatingCapacity)="data"> {{ data.item.seatingCapacity ? data.item.seatingCapacity : 'N/A' }} </template>
          <template #cell(transmissionValue)="data"> {{ data.item.transmissionValue ? data.item.transmissionValue : 'N/A' }} </template>
          <template #cell(user)="data"> {{ data.item.user ? data.item.user : 'N/A' }} </template>
          <template #cell(vinNo)="data">{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</template>
          <template #cell(wheelbase)="data"> {{ data.item.wheelbase ? data.item.wheelbase : 'N/A' }} </template>
          <template #cell(width)="data"> {{ data.item.width ? data.item.width : 'N/A' }} </template>

          <template #cell(fuelValue)="data"> {{ data.item.fuelValue ? data.item.fuelValue : 'N/A' }} </template>

          <template #cell(bodyValue)="data"> {{ data.item.bodyValue ? data.item.bodyValue : 'N/A' }} </template>

          <template #cell(msrpCurrencyValue)="data">{{ data.item.msrpCurrencyValue ? data.item.msrpCurrencyValue : 'N/A' }}</template>
          <template #cell(userId)="data"> {{ data.item.userId ? data.item.userId : 'N/A' }} </template>
          <template #cell(appraisedPrice)="data"> {{ data.item.appraisedPrice ? formatPrice(data.item.appraisedPrice, 2) : formatPrice(0) }} </template>
          <template #cell(sellingPrice)="data">{{ data.item.sellingPrice ? formatPrice(data.item.sellingPrice, 2) : formatPrice(0) }} </template>
          <template #cell(accessories)="data"> {{ data.item.accessories ? data.item.accessories : 'N/A' }} </template>
          <template #cell(totalMsrpOptions)="data">{{ data.item.totalMsrpOptions ? formatPrice(data.item.totalMsrpOptions, 2) : formatPrice(0) }} </template>
          <template #cell(msrpTotal)="data"> {{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }} </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" v-if="$Can('inventory_show') || $Can('inventory_edit')">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'inventory-view', params: { id: data.item.id } }" v-if="$Can('inventory_show')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details </span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$Can('inventory_show')" @click="showPayment(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Payment Schedule </span>
              </b-dropdown-item>

              <!-- v-if="data.item.status == 'A'" -->
              <b-dropdown-item :to="{ name: 'inventory-edit', params: { id: data.item.id } }" v-if="$Can('inventory_edit') && data.item.status == 'A'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row cols="12" class="mt-2">
                <!-- solid color -->
                <b-col md="6" xl="3">
                  <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                    <b-card-title>
                      {{ formatPrice(row.item.buyout, 2) }}
                    </b-card-title>
                    <b-card-text>
                      Current Buyout
                    </b-card-text>
                  </b-card>
                </b-col>

                <!-- solid color -->
                <b-col md="6" xl="3">
                  <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                    <b-card-title>
                      {{ formatPrice(row.item.interestCharge, 2) }}
                    </b-card-title>
                    <b-card-text>
                      Current Interest Charge
                    </b-card-text>
                  </b-card>
                </b-col>

                <!-- solid color -->
                <b-col md="6" xl="3">
                  <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                    <b-card-title> {{ formatPrice(row.item.principalPmt, 2) }} </b-card-title>
                    <b-card-text> Current Principal Charge </b-card-text>
                  </b-card>
                </b-col>

                <b-col md="6" xl="3">
                  <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                    <b-card-title> {{ formatPrice(row.item.adminBalance, 2) }} </b-card-title>
                    <b-card-text> Current Admin Charge </b-card-text>
                  </b-card>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <h5>Payment Details</h5>

                  <b-table
                    striped
                    hover
                    ref="purchaseTable"
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowLeaseSelected"
                    :fields="quoteFields"
                    :items.sync="row.item.leasePayments"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.inceptionId) }}</strong>
                    </template>

                    <template #cell(get_user)="row">
                      <span>{{ row.item.get_user ? row.item.get_user.name + ' ' + row.item.get_user.surname : 'N/A' }}</span>
                    </template>

                    <template #cell(inceptionDate)="row">
                      <span>{{ row.item.inceptionDate ? row.item.inceptionDate : 'N/A' }} </span>
                    </template>

                    <template #cell(pmtDate)="row">
                      <span>{{ row.item.pmtDate ? row.item.pmtDate : 'N/A' }} </span>
                    </template>

                    <template #cell(pmtNumber)="row">
                      <strong class="text-primary">#{{ Number(row.item.pmtNumber) }}</strong>
                    </template>

                    <template #cell(interestAmount)="row">
                      <span>{{ formatPrice(row.item.interestAmount, 2) }}</span>
                    </template>

                    <template #cell(principalBalance)="row">
                      <span> {{ formatPrice(row.item.principalBalance, 2) }}</span>
                    </template>

                    <template #cell(adminBalance)="row">
                      <span> {{ formatPrice(row.item.adminBalance, 2) }}</span>
                    </template>
                    <template #cell(buyout)="row">
                      <span> {{ formatPrice(row.item.principalBalance + row.item.adminBalance, 2) }}</span>
                    </template>

                    <template #cell(totalPayment)="row">
                      <span> {{ formatPrice(row.item.totalPayment, 2) }}</span>
                    </template>

                    <template #cell(paid)="row">
                      <b-badge variant="light-success" v-if="row.item.paid == true" style="font-size: 70%">Payment Received</b-badge>
                      <b-badge variant="light-info" v-if="row.item.paid == false && row.item.pastDue == null && row.item.status != 'D'" style="font-size: 70%">Payment Pending</b-badge>
                      <b-badge variant="light-danger" v-if="row.item.paid == false && row.item.pastDue == 'yes'" style="font-size: 70%"
                        >Payment Pending <br />
                        <small>Past Due</small>
                      </b-badge>
                      <b-badge variant="light-warning" v-if="row.item.paid == false && row.item.status == 'D' && row.item.pastDue == null" style="font-size: 70%">Partial Payment </b-badge>
                      <b-badge variant="light-danger" v-if="row.item.paid == false && row.item.status == 'D' && row.item.pastDue == 'yes'" style="font-size: 70%"
                        >Partial Payment <br />
                        <small>Past Due</small>
                      </b-badge>
                    </template>

                    <template #cell(archive)="row">
                      <span v-if="row.item.archive == 'yes'">YES</span>
                      <span v-if="row.item.archive == 'no'">NO</span>
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager </b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="warning" class="badge-glow">Approved </b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved </b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small> </b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
                      <b-badge v-if="row.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge">Canceled Purchase </b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge">Win-Closed</b-badge>
                    </template>

                    <template #cell(actions)="data" v-if="$Can('inventory_show') || $Can('inventory_edit')">
                      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item :to="{ name: 'payment-preview', params: { id: data.item.id } }" v-if="$Can('inventory_show')">
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">Details </span>
                        </b-dropdown-item>

                        <b-dropdown-item v-if="$Can('inventory_show')" @click="showPayment(data.item)">
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">Add Payment</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
            </b-card>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import testleaseStoreModule from './testleaseStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: { Treeselect, vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, flatPickr, moment },

  setup() {
    const LEASE_APP_STORE_MODULE_NAME = 'testlease';

    // Register module
    if (!store.hasModule(LEASE_APP_STORE_MODULE_NAME)) store.registerModule(LEASE_APP_STORE_MODULE_NAME, testleaseStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEASE_APP_STORE_MODULE_NAME)) store.unregisterModule(LEASE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const statusWebsiteOptions = [
      { text: 'Published', value: 'yes' },
      { text: 'Unpublished', value: 'no' },
    ];

    const tempStatusOptions = [
      { text: 'Created', value: 'A' },
      { text: 'Incoming - Consigment', value: 'B' },
      { text: 'In Stock - Consigment', value: 'C' },
      { text: 'Incoming', value: 'D' },
      { text: 'In Stock Pending', value: 'I' },
      { text: 'In Stock - Pending Receiving', value: 'E' },
      { text: 'In Stock - Pending Payment', value: 'F' },
      { text: 'In Stock', value: 'G' },
      { text: 'Created', value: 'P' },

      { text: 'Sold Pending', value: 'PA' },
      { text: 'Delivered - Pending Payment', value: 'PB' },
      { text: 'Sold', value: 'PC' },
      { text: 'Delivered', value: 'PD' },
      { text: 'Closed', value: 'PE' },

      { text: 'In Stock Pending - Sold Order', value: 'PF' },
      { text: 'In Stock Pending - Sold Order', value: 'PL' },

      { text: 'Incoming - Sold Order', value: 'PG' },
      { text: 'Consigment - Sold Order', value: 'PH' },
      { text: 'Sold Order', value: 'PI' },
      { text: 'Funded - Pending Delivery', value: 'PK' },
    ];

    const tempQuoteStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Sale', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    return {
      where: router.currentRoute.name,
      filter: null,
      filterOn: ['id', 'title', 'status', 'listingPrice', 'publish'],
      quoteFields: [
        { key: 'pmtNumber', label: 'PAYMENT NUMBER', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'interestAmount', label: 'INTEREST AMOUNT', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'totalPayment', label: 'TOTAL PAYMENT', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'principalBalance', label: 'PRINCIPAL BALANCE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'adminBalance', label: 'ADMIN BALANCE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'buyout', label: 'BUYOUT', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'postingDate', label: 'DUE DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'pmtDate', label: 'PAYMENT DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'paid', label: 'PAYMENT', class: 'text-center', thStyle: 'width: 10%' },
      ],

      orderFields: [
        { key: 'id', label: 'ORDER ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'get_user', label: 'USER NAME', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'type', label: 'TYPE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'status', label: 'STATUS', class: 'text-center', thStyle: 'width: 20%' },
      ],

      packFields: [
        { key: 'id', label: 'VEHICLE ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'age', label: 'AGE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'type', label: 'TYPE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 30%' },
      ],

      perPage: localStorage.getItem('inventoryShow') ?? 8,
      fetchInventorys: [],
      listInventorys: [],
      currentPage: 1,
      perPageOptions: [5, 8, 25, 50, 100],
      tempQuoteStatusOptions,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusWebsiteOptions,
      tempStatusOptions,
      statusOptions: [],
      statusWebsite: null,
      status: null,
      treeStatus: null,
      inHeaders: [],
      colWidthFactor: null,
      filterDateStart: null,
      filterDateEnd: null,
      rangeDate: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('inventoryShow', val);
        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.listRefresh();
        }
      },
    },

    // statusWebsite: {
    //   handler: function(val, before) {
    //     this.listRefresh();
    //   },
    // },

    // status: {
    //   handler: function(val, before) {
    //     this.listRefresh();
    //   },
    // },

    treeStatus: {
      handler: function(val, before) {
        if (before == null) {
          this.listRefresh();
        }
        if (before != null) {
          if (val.length != before.length) {
            this.listRefresh();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val.includes('to')) {
          this.filterDateStart = this.rangeDate.split(' to ')[0];
          this.filterDateEnd = this.rangeDate.split(' to ')[1];

          this.listRefresh();
        }
      },
    },
  },

  methods: {
    onRowLeaseSelected(item) {
      if (this.$Can('order_show')) {
        router.push({ name: 'payment-preview', params: { id: item[0].id } });
      }
    },

    showPayment(item) {
      this.getSingleItem(item);
    },

    getSingleItem(item) {
      this.loading = true;

      store
        .dispatch('testlease/leasePaymentsInventory', item.leaseInceptionId)
        .then((res) => {
          this.listInventorys.forEach((element) => {
            element._showDetails = false;
          });

          item.leasePayments = res.data.leasePayments;
          item.buyout = res.data.buyout;
          item.principalBalance = res.data.principalBalance;
          item.adminBalance = res.data.adminBalance;
          item.interestCharge = res.data.interestCharge;
          item.adminCharge = res.data.adminCharge;
          item.principalPmt = res.data.principalPmt;
          item.currentPmtNo = res.data.currentPmtNo;

          item._showDetails = !item._showDetails;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    listRefresh() {
      this.getItems();
    },

    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'testlease-inventory-view', params: { id: item[0].id } });
      }
    },

    onError(event) {
      event.target.src = '../no-car.png';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: 'Status',
          children: [],
        },
        {
          id: 2000000,
          label: 'Publish Status',
          children: [],
        },
      ];
      this.tempStatusOptions.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.text,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      this.statusWebsiteOptions.forEach((web) => {
        var setWeb = {
          id: web.value,
          label: web.text,
          value: web.value,
          is: 'web',
        };
        treeOptionsStatus[1].children.push(setWeb);
      });

      this.statusOptions = treeOptionsStatus;
    },

    getItems() {
      this.loading = true;
      let filterStatus = [];
      let filterWeb = [];
      if (this.treeStatus != null) {
        filterStatus = _.filter(this.treeStatus, { is: 'status' });
        filterWeb = _.filter(this.treeStatus, { is: 'web' });
      } else {
        filterStatus = null;
        filterWeb = null;
      }
      store
        .dispatch('testlease/fetchLeaseInventorys', {
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filter: this.filter,
          statusWebsite: filterWeb,
          status: filterStatus,
          filterDateStart: this.filterDateStart,
          filterDateEnd: this.filterDateEnd,
        })
        .then((res) => {
          var arrayStatus = [];
          //this.statusOptions = [];

          let filterObj = {
            perPage: this.perPage,
            page: this.currentPage,
            status: filterStatus ? filterStatus : [],
            web: filterWeb ? filterWeb : [],
          };

          //  if (filterObj.status.length > 0 || filterObj.make.length > 0) {
          localStorage.setItem('filterLeaseInventoryList', JSON.stringify(filterObj));
          //  }

          res.data.data.data.forEach((element) => {
            arrayStatus.push(element.status);
            element.created_at = moment(element.created_at.split(' ')[0]).format('YYYY-MM-DD');
            element._showDetails = false;
          });

          this.totalRows = res.data.data.total;
          this.fetchInventorys = res.data.data.data;
          this.listInventorys = res.data.data.data;

          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          if (items == 'stockNumber' || items == 'status' || items == 'condition' || items == 'titleValue' || items == 'mileageValue' || items == 'listingPrice' || items == 'publish') {
            this.inHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
          } else {
            this.inHeaders.push({ key: items, label: columns.title, sortable: false, class: 'text-center', thStyle: width });
          }
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({ key: 'actions', class: 'text-center', thStyle: 'width: 5%' });
        }
        this.loading = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    storageLeaseInventoryItem() {
      let key = JSON.parse(localStorage.getItem('filterLeaseInventoryList'));

      if (key == null) {
        this.listRefresh();
      }

      if (key) {
        // this.perPage = key.perPage;
        // this.page = key.page;
        // this.rangeDate = `${this.filterDateStart} to ${this.filterDateEnd}`;
        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        if (key.web.length > 0) {
          key.web.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;

        this.listRefresh();
      }
    },
  },

  mounted() {
    //this.listRefresh();
    this.filterTreeSelect();
    this.storageLeaseInventoryItem();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
